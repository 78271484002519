import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const updateApprovalList = createAsyncThunk(
  'updateApproval/updateApproval',
  async (params) => {
    const option = {
      method: 'PUT',
      body: JSON.stringify(params.payload),
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/approvalworkflow/approvallist'}/${params.id}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const updateApprovalSlicePost = createSlice({
  name: 'updateApproval',
  initialState: {
    updateApprovalList: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateApprovalList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateApprovalList.fulfilled, (state, action) => {
      state.updateApprovalList = action.payload.data;
      state.loading = false;
    });
    builder.addCase(updateApprovalList.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default updateApprovalSlicePost.reducer;
