import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchAllProperty = createAsyncThunk(
  'propertyAll/fetchAllProperty',
  async () => {
    // let query = Object.keys(params)
    //   .map((k) => {
    //     if (typeof params[k] === 'object') {
    //       return Object.keys(params[k])
    //         .map((subKey) => {
    //           return `${k}[${subKey}]=${encodeURIComponent(params[k][subKey])}`;
    //         })
    //         .join('&');
    //     } else {
    //       return `${k}=${params[k]}`;
    //     }
    //   })
    //   .join('&');
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/property/getall'}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const propertyAllSlice = createSlice({
  name: 'propertyAll',
  initialState: {
    propertyAll: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllProperty.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllProperty.fulfilled, (state, action) => {
      state.propertyAll = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchAllProperty.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default propertyAllSlice.reducer;
