import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchAllOfferContractTemplates = createAsyncThunk(
  'contractTemplate/fetchAllOfferContractTemplates',
  async (params) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
    const option = {
      method: 'GET',
      // body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/contractgenerate/?' + query}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);
const initialState = {
  contractTemplate: [],
  loading: false,
  nextPage: 1,
};
const ContractTemplateGetAllSlice = createSlice({
  name: 'contractTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllOfferContractTemplates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAllOfferContractTemplates.fulfilled,
      (state, action) => {
        state.contractTemplate = action.payload.data;
        state.loading = false;
      }
    );
    builder.addCase(fetchAllOfferContractTemplates.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default ContractTemplateGetAllSlice.reducer;
